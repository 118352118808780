import React, {useState} from 'react';
import cx from 'classnames';
import Link from 'next/link';
import Undo from '@ant-design/icons/ArrowLeftOutlined';
import NumberFormat from 'react-number-format';
import {
  Button as AButton,
  Input as AInput,
  Col,
  Row,
  Spin as ASpin,
  Space,
  Checkbox,
  Alert,
} from 'antd';
import {SlideDown} from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import ArrowDown from '@ant-design/icons/ArrowDownOutlined';
import Arrow from '../svg/arrow-tr.svg';
import Close from '@ant-design/icons/CloseCircleTwoTone';
import SvgLogo from '../svg/logo.svg';
import styles from '../styles/ui.module.sass';
import SvgAlert from '../svg/alert.svg';

export const HeadCover = ({variant, ...props}: any) => {
  return (
    <div
      {...props}
      className={cx(styles.headCover, {
        [styles[variant]]: variant,
      })}
    ></div>
  );
};

export const HeadSection = ({pic, ...props}: any) => {
  return (
    <div
      {...props}
      className={cx(styles.headSection, {[styles.pic]: pic})}
    ></div>
  );
};

export const TopBar = (props) => {
  return <div className={styles.topBar}>{props.children}</div>;
};

export const Block = ({typo, minHeight, ...props}: any) => {
  return (
    <div
      className={cx(styles.block, {
        [styles.typo]: typo,
        [styles.minHeight]: minHeight,
      })}
    >
      {props.children}
    </div>
  );
};

export const FormBlock = ({msg, narrow, huge, message, ...props}: any) => {
  return (
    <div
      className={cx(styles.formBlock, {
        [styles.msg]: msg,
        [styles.huge]: huge,
        [styles.message]: message,
        [styles.narrow]: narrow,
      })}
    >
      {props.children}
    </div>
  );
};

export const Section = ({className, dark, ...props}: any) => {
  return (
    <div
      {...props}
      className={cx(styles.section, className, {[styles.dark]: dark})}
    ></div>
  );
};

export const ImageHome = (props) => {
  return <div {...props} className={styles.imageHome}></div>;
};

export const Input = ({className, white, ...props}: any) => {
  return (
    <AInput
      className={cx(styles.input, {
        'ant-input-huge': props.size === 'huge',
        [styles.alignCenter]: props.align === 'center',
        'ant-white': white,
      })}
      {...props}
    />
  );
};

export const AmountInput = ({size, white, ...props}: any) => {
  return (
    <NumberFormat
      thousandSeparator
      autoComplete="off"
      prefix={'$'}
      className={cx('ant-input', {
        'ant-input-lg': size === 'large',
        'ant-input-huge': size === 'huge',
        'ant-white': white,
        [styles.alignCenter]: props.align === 'center',
      })}
      {...props}
    />
  );
};

export const FormatNumber = ({value, plain}: any) => {
  return (
    <NumberFormat
      thousandSeparator
      autoComplete="off"
      displayType="text"
      prefix={plain ? undefined : '$'}
      value={value}
      decimalScale={2}
    />
  );
};
export const Button = React.forwardRef(
  ({className, rounded, active, wide, white, ...props}: any, ref) => {
    return (
      <AButton
        ref={ref}
        className={cx(
          styles.button,
          {
            [styles.rounded]: rounded,
            [styles.wide]: wide,
            [styles.active]: active,
            'ant-btn-huge': props.size === 'huge',
            'no-style': !props.type,
            'ant-white': white,
          },
          className
        )}
        {...props}
      ></AButton>
    );
  }
);

export const Page = (props) => {
  return <div {...props}></div>;
};

export const Q = (props) => {
  return <h2 className={styles.q} {...props}></h2>;
};

export const Cap = (props) => {
  return <div {...props} className={styles.cap}></div>;
};

export const FormSection = (props) => {
  return (
    <div className={styles.formSection}>
      <Row gutter={24}>
        <Col xs={24} md={16}>
          {props.children}
        </Col>
      </Row>
    </div>
  );
};
export const Helper = (props) => {
  return (
    <div className={cx(styles.helper, {[styles.sub]: props.sub})}>
      {props.children}
    </div>
  );
};

export const Card = ({className, ...props}: any) => {
  return <div className={cx(styles.card)} {...props}></div>;
};

export const Items = (props: any) => {
  return <div className={cx(styles.items)} {...props}></div>;
};

export const Score = (props: any) => {
  return <div className={cx(styles.score)} {...props}></div>;
};

export const GetStarted = (props: any) => {
  return <div className={cx(styles.getStarted)} {...props}></div>;
};

let logo = <SvgLogo />;

export const Logo = (props) => {
  if (props.clickable === false) {
    return logo;
  }
  return (
    <Link href="/">
      <a className={styles.logo}>{logo}</a>
    </Link>
  );
};

export let delay = (t = 5) => new Promise((d) => setTimeout(d, t));

const antIcon = <LoadingOutlined style={{fontSize: 40}} spin />;

export let Spinner = ({fullScreen, absolute, fixed, text}: any) => {
  return (
    <div
      className={cx(styles.spinner, {
        [styles.fullScreen]: fullScreen,
        [styles.absolute]: absolute,
        [styles.fixed]: fixed,
      })}
    >
      <span>
        <ASpin indicator={antIcon} />
        {text && <span className={styles.spinText}>{text}</span>}
      </span>
    </div>
  );
};

export let Spin = () => <ASpin indicator={antIcon} />;

export let CloseButton = () => {
  return (
    <a className={styles.closeButton}>
      <Close />
    </a>
  );
};

export let Terms = () => {
  return (
    <Row justify="center" className={styles.terms}>
      <Space align="center" direction="vertical" size={20}>
        <SvgAlert className={styles.svgAlert} />

        <div>
          <p>
            Actions on ILOCX will directly affect your Director Check and
            Reputation scores. Preliminary due diligence and compliance checks
            have been attached to this application to be reviewed by ILOCX. All
            term sheets are proposals only based on the information provided at
            the time of the application. We reserve the right to withdraw the
            term sheet at any time. Our term sheet covers confidentiality for
            both parties. Please sign and return it.
          </p>

          <p>
            ILOCX will continually monitor progress on your web scores, director
            scores and other key pieces of data.
          </p>
        </div>
      </Space>
    </Row>
  );
};

export let AgreeSubmit = (props: any) => {
  let {formik} = props;
  let {errors, touched, values} = formik;
  return (
    <div className={styles.agree}>
      <Row gutter={[24, 16]}>
        <Col sm={12}>
          <Row justify="end" align="middle" style={{minHeight: '100%'}}>
            <Col>
              <div>
                <Checkbox
                  name="agree"
                  checked={values.agree}
                  onChange={formik.handleChange}
                >
                  I agree to the Terms and Conditions
                </Checkbox>

                {formik.submitCount > 0 && errors.agree && (
                  <Alert type="warning" message={errors.agree} />
                )}
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          <Button
            wide
            htmlType="submit"
            loading={formik.isSubmitting}
            disabled={formik.isSubmitting}
            size="huge"
            type="primary"
          >
            Submit My Application
          </Button>
        </Col>
      </Row>

      <Terms />
    </div>
  );
};

export const scores = [
  'amount',
  'issuePrice',
  'licensesNumber',
  'totalSales',
  'royaltyPayment',
  'openingTradingPrice',
];

let labels = {
  amount: 'Raising',
  issuePrice: 'Issue Price',
  licensesNumber: 'Buyers',
  totalSales: 'Revenue',
  royaltyPayment: 'Royalty Payment',
  openingTradingPrice: 'Opening Trading Price',
};

export const Scores = (props) => {
  return (
    <Row>
      {scores.map((name) => {
        return (
          <Col key={name} xs={props.xs || 12} sm={props.sm || 8}>
            <Score>
              <label>{labels[name]}</label>
              <strong>
                <FormatNumber
                  value={props.scores[name]}
                  plain={name === 'licensesNumber'}
                />
              </strong>
            </Score>
          </Col>
        );
      })}
    </Row>
  );
};

export const ButtonSelect = (props) => {
  return (
    <div className={styles.btnSelect}>
      {props.options.map((o) => {
        return (
          <Button
            size="large"
            type="ghost"
            active={props.value === o.value}
            key={o.value}
            onClick={() => props.onChange(o.value)}
            {...props.buttonProps}
          >
            {o.label}
          </Button>
        );
      })}
    </div>
  );
};

export const Year = ({className, year, ...props}: any) => {
  return (
    <div
      {...props}
      className={cx(styles.year, className, {
        [styles.year2]: year === 2,
      })}
    ></div>
  );
};

export const YearImg = ({className, ...props}: any) => {
  return <div {...props} className={cx(styles.yearImg, className)}></div>;
};

export const Reasons = ({className, ...props}: any) => {
  return <div {...props} className={cx(styles.reasons, className)}></div>;
};

export const Reason = ({className, ...props}: any) => {
  return (
    <div
      {...props}
      tabIndex={0}
      className={cx(styles.reason, className)}
      onFocus={(e) => {
        try {
          // @ts-ignore
          e.currentTarget.querySelector(`.read-more`).click();
        } catch (e) {}
      }}
    ></div>
  );
};

export const ReasonMore = (props) => {
  let [open, toggle] = useState(false);
  return (
    <div>
      <SlideDown className={'my-dropdown-slidedown'}>
        {open ? props.children : null}
      </SlideDown>

      <a className="read-more" onClick={() => toggle(!open)}>
        <span>
          Read more <ArrowDown />
        </span>
      </a>
    </div>
  );
};

export const Back = ({to, children}) => {
  return (
    <Link href={to}>
      <Button className={styles.back} icon={<Undo />} type="link">
        {children}
      </Button>
    </Link>
  );
};

export const CompanyNumber = ({className, info, ...props}: any) => {
  return (
    <a
      href={info.opencorporates_url}
      {...props}
      className={cx(styles.companyNumber, className)}
    >
      {info.company_number}
      <Arrow />
    </a>
  );
};
